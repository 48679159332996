import React from "react"
import cx from "classnames"
import Collapse from "@kunukn/react-collapse"
import { Link, Element } from "react-scroll"

class Part2 extends React.Component {
  state = {
    isOpen: false,
  }

  render() {
    return (
      <div className="accordion">
        <button
          className={cx("accordion__open-button", {
            "accordion__open-button--active": this.state.isOpen,
          })}
          onClick={() => this.toggle()}
          aria-expanded={this.state.isOpen ? "true" : "false"}
          aria-controls="section2"
          id="accordion2"
        >
          <span className="accordion__trigger">
            Part II
            <br />
            Accomodations
          </span>
        </button>

        <Collapse
          isOpen={this.state.isOpen}
          id="section2"
          aria-labelledby="accordion2"
          role="region"
          aria-hidden={this.state.isOpen ? "true" : "false"}
          className={
            "accordion__collapse " +
            (this.state.isOpen ? "accordion__collapse--active" : "")
          }
        >
          <div className="accordion__content">
            <p>
              This section provides a list of recommendations that focus
              primarily on the hard and soft architecture of accessibility.
              These are not legal requirements: these guidelines are meant to
              facilitate actual infrastructural change while proposing solutions
              for how institutions of varying resources, personnel, and building
              management might meet the call to eradicate access barriers.
            </p>

            <hr />

            <h2>Table of Contents:</h2>

            <ul>
              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="american-sign-language-interpretation"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  American Sign Language Interpretation
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="audio-description"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Audio Description
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="communication-access-real-time-translation-cart"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Communication Access Real-Time Translation (CART)
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="chemical-sensitivity-and-air-quality"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Chemical Sensitivity and Air Quality
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="childcare"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Childcare
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="closed-captioning"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Closed Captioning
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="communication"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Communication
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="consent"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Consent
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="content-warnings"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Content warnings
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="food-and-dietary-restrictions"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Food and Dietary Restrictions
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="harm-reduction-and-overdose-preparedness"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Harm Reduction and Overdose Preparedness
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="image-captions-for-web-accessibility"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Image Captions for Web Accessibility
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="lighting-and-flash-recording"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Lighting and Flash Recording
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="live-streaming-events"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Live Streaming Events
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="mobility"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Mobility
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="personal-care-assistants-pca-and-service-animals"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Personal Care Assistants (PCA) and Service Animals
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="restrooms"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Restrooms
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="spanish-language"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Spanish Language
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="seating-and-seating-arrangements"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Seating and Seating arrangements
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="sensory-rooms"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Sensory Rooms
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="sliding-scales-and-economic-justice"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Sliding Scales and Economic Justice
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="text"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Text
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="touch-tours"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Touch Tours
                </Link>
              </li>

              <li>
                <Link
                  activeClass="active"
                  className="table-of-contents-item"
                  to="transportation"
                  spy={true}
                  offset={-36}
                  smooth={true}
                  duration={500}
                >
                  Transportation
                </Link>
              </li>
            </ul>

            <hr />

            <Element
              name="american-sign-language-interpretation"
              className="element"
            >
              <h2>American Sign Language Interpretation</h2>
            </Element>

            <p>
              There are multiple tools and resources available for supporting
              d/Deaf and Hard of Hearing (HOH) members of an arts community.
              While smaller scale arts spaces may not have the resources to
              provide American Sign Language (ASL) interpretation at every
              event, they should be able to offer it as an option for most
              events. Part III of this guide will provide more detailed
              information about how to list access information in advance of an
              event. To have an event properly interpreted, you will most likely
              need to hire two interpreters over the course of an event.
              Interpreters will often ask for a list of key terms, or some texts
              to help familiarize themselves with the language of the event.
              Some basic manners with interpreters: please do not address them
              directly while they are interpreting, because they are working. If
              you are communicating with someone who primarily communicates via
              lipreading and/or ASL, please respond directly to them with your
              words, gaze, and body language, and not to their interpreter.
            </p>

            <Element name="audio-description" className="element">
              <h2>Audio Description</h2>
            </Element>

            <p>
              Audio Description is a service that narrates visual content to
              increase accessibility for the blind and visually impaired. It can
              also be helpful for people with learning and cognitive
              disabilities. For performances, this can take the form of live
              narration through a headset. Audio describers provide real-time
              narration which connects to a headset for the audio describer
              user. For visual media, audio description can be incorporated as
              its own discrete soundtrack. The audio description symbol is the
              letters “AD” followed by three parentheses. Ideally, institutions
              should make professional audio description available at their
              events. An alternative, cost-effective option is to have an
              organization’s staff trained in audio description, as there are
              many AD training programs available nationally.
            </p>

            <Element
              name="communication-access-real-time-translation-cart"
              className="element"
            >
              <h2>Communication Access Real-Time Translation (CART)</h2>
            </Element>

            <p>
              CART is a real-time, speech-to-text captioning system that can be
              used for live and remote events broadcast online. A stenographer
              or captioner does the real-time transcription which is then
              displayed on a monitor or an LED board. CART is helpful for the
              deaf and hard of hearing (HOH), people with English as a second
              language, and people with learning and cognitive disabilities. Not
              all members of the deaf and HOH community speak ASL, so CART can
              be a critical point of communication access. It also produces a
              transcript of events for further use and archiving. CART service
              providers will bring all the required equipment and their
              services, like ASL, should be arranged well in advance of an
              event.
            </p>

            <Element
              name="chemical-sensitivity-and-air-quality"
              className="element"
            >
              <h2>Chemical Sensitivity and Air Quality</h2>
            </Element>

            <p>
              People with multiple chemical sensitivities and chemical injuries
              are incredibly sensitive to petroleum-based chemicals and
              fragrances that permeate body products, cleaning products,
              building materials, and foods. Most household and industrial
              materials off-gas chemicals which can trigger a wide array of
              responses inclusive of migraines, cognitive impairments, asthma,
              and other allergic reactions. The air is a shared resources and
              indoor air quality is an access issue for many disabled people
              (especially the chronically ill). A few measures can be taken to
              create a more accessible shared air space.
            </p>

            <p>
              Institutions can ask their patrons to come to events
              fragrance-free. It can be difficult to regulate what people choose
              to put on their bodies, but if a space is labeled as
              fragrance-free it will discourage people from using perfume and
              cologne before attending an event. Organizations can also impact
              air quality by using non-toxic, unscented cleaning product without
              “fragrance.” “Fragrance” is an unregulated substance found in
              ingredient lists that is a common irritant for chemically
              sensitive people. Cleaning common spaces regularly to remove dust
              and other allergens is recommended as well as periodically having
              arts spaces checked for mold. Smoking areas should be clearly
              designated at a minimum of 5 feet from the entrance of the space.
            </p>

            <Element name="childcare" className="element">
              <h2>Childcare</h2>
            </Element>

            <p>
              In an ideal world, cultural organizations wouldn’t have to provide
              childcare because it would already be covered by the state.
              Unfortunately that’s not the case, but there are some critical
              things that arts spaces can provide to facilitate access for
              parents, caregivers, and children. Playstations or even a box with
              toys and drawing materials can be incredibly helpful to people
              with children. Most restrooms in public buildings are mandated to
              have baby-changing stations. Baby-changing stations should be
              installed in all gendered restrooms, not just the “women’s”
              restroom. Simply stating that “children are welcome” or that your
              program includes “adult material” can be very helpful to
              caregivers in determining whether your event is accessible to them
              or not.
            </p>

            <Element name="closed-captioning" className="element">
              <h2>Closed Captioning</h2>
            </Element>

            <p>
              Closed captioning facilitates access for the deaf and
              hearing-impaired (HOH). It includes text-based transcription of
              dialogue and description of sounds, affect, and music. A variety
              of media content also provide closed captions and can be
              recognized by the closed caption symbol of two white “Cs” in a
              black box. DVD media often contain closed captions under the
              subtitles menu. YouTube provides an option for closed captions as
              well as a separate transcript of the captions. Arts spaces can
              facilitate access by using closed captions that are already
              provided with media and they can also easily produce original
              captions for content. Resources and free software for generating
              captions can be found in Part IV of this guide.
            </p>

            <Element name="communication" className="element">
              <h2>Communication</h2>
            </Element>

            <p>
              People with psychological, developmental, and/or cognitive
              disabilities face incredible barriers to community access. It’s
              important to hold space and awareness for varied communication
              styles. It’s helpful to speak slowly and allow for variations in
              people’s response time. Institutions should not make assumptions
              about how to read the communication styles of others. According to
              narrow, nondisabled communication styles, some people may appear
              to be less responsive than they are and vice versa. Some disabled
              people may need to twitch, pace, and/or not maintain eye contact.
              The way organizations and their staff communicate with their
              public is the primary mode of accessibility in non-profit arts
              spaces. When an event is being introduced, the language of
              accessibility should be brought into the space. Statements can be
              made that announce an organization’s willingness to make
              accommodations if anyone needs assistance. Viewers and audience
              members should be directed to a specific members of staff who will
              troubleshoot last-minute accommodations. Another helpful tip is to
              limit the use of jargon which can be a barrier to those who are
              dealing with cognitive and developmental impairments and/or those
              who do not have access to higher education. Access needs are not
              static; they can change in relation to infinite factors. Staff
              members should introduce themselves often and use names
              repeatedly. If there are multiple points throughout an event when
              a staff member is addressing the audience, they can recapitulate
              the evening and explain the different phases of the event. People
              should be invited to clarify points of inquiry or any confusion
              they have. A lot of access is primarily about letting guards down
              and facilitating an environment where needs can be met.
            </p>

            <Element name="consent" className="element">
              <h2>Consent</h2>
            </Element>

            <p>
              Organizations can facilitate more accessible environments by
              creating an institutional culture that privileges consent in
              interpersonal interactions. Before entering into someone’s
              personal space, asking for consent should be encouraged in regards
              to touching other people’s bodies, hair, assistive devices, etc.
              In the event of performance works that are interactive or require
              accessing the personal space of audience members, staff should
              highlight this aspect of the event in event literature and/or
              while introducing the event itself.
            </p>

            <Element name="content-warnings" className="element">
              <h2>Content warnings</h2>
            </Element>

            <p>
              Content warnings facilitate access for people with psychological
              disabilities, especially people who live with Post-Traumatic
              Stress Disorder (PTSD). Content warnings are notes that can be
              mentioned verbally at an event, or written at the beginning of a
              text or video online, that name potentially triggering content.
              PTSD triggers can cause survivors of abuse or trauma to experience
              panic attacks, psychotic breakdowns, and a wide host of emotional
              and physiological symptoms. Anything could be potentially
              triggering depending on the individual’s experience of trauma but
              there are common topics which should be mentioned in a content
              warning: rape and sexual assault, suicide and self-harm, incest
              and child abuse, and gendered and racialized violence. Content
              warnings have, in recent history, come under scrutiny as a form of
              censorship, yet warnings provide increased access to content
              through transparency. While institutions cannot control someone’s
              experience in an art space, they can provide their audiences with
              the knowledge and the tools to navigate the space and their own
              mental health.
            </p>

            <Element name="food-and-dietary-restrictions" className="element">
              <h2>Food and Dietary Restrictions</h2>
            </Element>

            <p>
              As food intolerances and allergies become more and more prevalent,
              there are some basic guidelines to follow when offering food to
              the public. Foods with known allergens and irritants (tree nuts,
              gluten, dairy, soy, seafood) should be clearly labeled. Certain
              foods, like peanuts, can result in serious hospitalizations for
              those with a peanut allergy. If you are hosting an event with
              snacks, please be mindful of people’s varied tolerances for foods
              and for example, purchase both regular and gluten-free products.
              Consider buying simple snacks with limited ingredients e.g..
              carrots with hummus. Also, if you are serving alcohol, please
              provide alternatives for those who do not drink. Food and drinking
              are such critical elements of sociality and arts events can be as
              inclusive as possible, allowing for people’s varied needs.
            </p>

            <Element
              name="harm-reduction-and-overdose-preparedness"
              className="element"
            >
              <h2>Harm Reduction and Overdose Preparedness</h2>
            </Element>

            <p>
              There are harm reduction measures that institutions can take to
              support the access needs of people living with chemical
              dependencies. Narcan (Naloxone) is a short-term opioid-blocker
              that can reverse fatal overdoses. Narcan can be purchased as an
              injectable or as an easy to use nasal spray. It costs between
              $20-$40 dollars and can be a life-saving intervention. Arts
              organizations should have Narcan on hand and staff should be
              familiar with how to administer it.
            </p>

            <Element
              name="image-captions-for-web-accessibility"
              className="element"
            >
              <h2>Image Captions for Web Accessibility</h2>
            </Element>

            <p>
              Most of the internet is inaccessible to blind and vision-impaired
              people. A screen reader is commonly used text-to-speech or
              text-to-Braille software that increases web accessibility. Screen
              readers cannot read images and so all images require image
              captions in order to be legible. Image captions are usually 1-3
              sentence descriptions of the content of an image. It’s best if
              they are as simply described as possible. Increasing web
              accessibility also supports those who are illiterate, live with
              learning disabilities, and/or have poor internet connections.
              Further guidelines on how to write image captions are in Part IV.
            </p>

            <Element name="lighting-and-flash-recording" className="element">
              <h2>Lighting and Flash Recording</h2>
            </Element>

            <p>
              Harsh lighting can trigger chronic migraines and photosensitive
              epilepsy. Fluorescent lights are significantly more triggering
              than LED natural lights. If an arts space has scalable lights,
              they should consider using dimmer lighting for events. Audience
              members should be be told in advance when stroboscopic lights are
              used in performances and screenings. If possible, flash
              photography should not be used during events. If it is absolutely
              necessary, patrons should be made aware during event
              introductions.
            </p>

            <Element name="live-streaming-events" className="element">
              <h2>Live Streaming events</h2>
            </Element>

            <p>
              Live Streaming or video documentation made available to the public
              are another critical tool for creating more accessible arts
              institutions. Many people’s primary encounters with art are
              through the internet. Arts spaces can be inaccessible for those
              who cannot leave their homes due to a wide array of disabilities.
              The technology will undoubtedly change over time, but there are
              many free applications available for live streaming. Currently,
              social media platforms with streaming services such as Facebook
              Live, YouTube Live, and Instagram Live are the most reliable and
              produce videos that can be archived for further use and
              dissemination. In addition, if your audience will be captured
              during the livestream, please let the audience know that they will
              be included before the event begins and ask for their consent. Be
              prepared to rearrange seats to accommodate audience responses.
            </p>

            <Element name="mobility" className="element">
              <h2>Mobility</h2>
            </Element>

            <p>
              While the ADA has a narrow scope of focus, its stipulations for
              architectural barriers are well worth noting. When these
              considerations are taken into account, a space can become
              accessible to a wide range of people with varying mobilities:
              wheelchair users, walker users, cane users, children, people
              carrying bags, and people pushing strollers. The standard ADA
              compliant door width is between 32 inches and 48 inches with a
              clearance of 36” around the door. Automated entrances are ideal,
              but a lever handle that can be used with one hand is prefered over
              a round knob which is less accessible. For a building that does
              not have an on-grade entrance (an entrance that is flush with the
              ground), ADA ramps require a slope ratio of 1:12. If a building
              cannot accommodate the installation of a ramp, temporary ramps can
              be purchased.
            </p>

            <Element
              name="personal-care-assistants-pca-and-service-animals"
              className="element"
            >
              <h2>Personal Care Assistants (PCA) and Service Animals</h2>
            </Element>

            <p>
              Many disabled people rely on personal care assistants for care
              work and for facilitating myriad points of access. If a disabled
              person visits an arts space and is accompanied by a PCA, their PCA
              should receive free admission. If it’s necessary to communicate
              with someone who is accompanied by a PCA, please respond to that
              individual with your words, gaze, and body language, and not their
              PCA.
            </p>

            <p>
              Service animals are trained animals that support people living
              with a wide variety of disability-related impairments. People with
              service animals should be welcomed into cultural spaces and
              accommodated. If a person with animal allergies is present, an
              organization’s staff should facilitate people occupying different
              parts of an arts space to mediate the situation.
            </p>

            <Element name="restrooms" className="element">
              <h2>Restrooms</h2>
            </Element>

            <p>
              Restrooms cross over into mobility access as well: For ADA
              compliance, restrooms or restroom stalls should be large enough to
              have a clear circle, wheelchair-turning radius of 60 inches. Sinks
              (or at least one sink, in a multi-stall bathroom) should be
              mounted under 34 inches from the ground with clearance underneath.
              Faucets should be accessible like door handles: either
              lever-operated, triggered by touch or movement, or
              electronically-operated. Toilets should be 17 to 19 inches off of
              the ground with grab bars on the side and rear wall, 33 to to 36
              inches off of the ground.
            </p>

            <p>
              In addition to architectural barriers, some considerations
              regarding gender not addressed by the ADA can significantly
              increase the accessibility of an arts space. Trans and
              gender-nonconforming people face significant barriers to safely
              using restrooms in public spaces. Restrooms are often
              gender-segregated and labeled as “men’s room” and “women’s room.”
              This kind of signage promotes gender policing which is a safety
              risk for people who do not fit neatly into prevailing cisexist and
              binary models of gender. Institutions can re-label restrooms
              (especially single-stall restrooms) with gender-inclusive signs by
              producing new vinyl signs or handwritten and taped signs,
              depending on respective budgets. Labels should state clearly “all
              gender restroom” or “gender-inclusive restroom.” Even if a
              restroom without signage is presumed to be all gender, it’s
              helpful to have it clearly marked.
            </p>

            <Element name="spanish-language" className="element">
              <h2>Spanish Language</h2>
            </Element>

            <p>
              The census reports that 17.8% of the U.S. population is
              Spanish-speaking. Institutions can greatly increase access by
              having well-translated bilingual text online on their website and
              in their print materials. Ideally all events would be bilingual
              with simultaneous Spanish language translation. Institutions may
              not be able to provide this at every event, but should make it
              clearly available as a resource for its spanish-speaking public.
            </p>

            <Element
              name="seating-and-seating-arrangements"
              className="element"
            >
              <h2>Seating and Seating arrangements</h2>
            </Element>

            <p>
              People of varying sizes and abilities require varying kinds of
              seating for an event. Organizations and institutions can invest in
              diverse seating with this in mind. Some things to consider: chairs
              that are too low can be difficult to move in an out of. Chairs
              with armrests can be helpful to be people with mobility
              restrictions while also being a barrier to fat people. The key is
              to have a set diverse seating options. Chairs with backs, rather
              than benches and stools, are also supportive for people in
              different kinds of bodies. It’s best to have modular seating that
              can be arranged differently for different events and different
              audiences. Chairs should be arranged to accommodate larger aisles
              (at least 36 inches) for wheelchairs and custom seating
              arrangements that leave space for wheelchairs along with stable
              chairs. Please be sure to keep aisles clear of bags and equipment.
            </p>

            <Element name="sensory-rooms" className="element">
              <h2>Sensory Rooms</h2>
            </Element>

            <p>
              Institutions can increase accessibility for a neurodiverse public
              by providing sensory rooms for sensory stimulation and calming. If
              an arts spaces has access to a room in addition to their primary
              exhibition or performance space, this can be turned into a sensory
              room. These separate spaces usually contain different objects that
              can support autistic people to regulate sensory input and
              stimulation. Weighted blankets, pillows, yoga mats, bosu balls,
              and dim lighting are a few things that can help create a sensory
              room.
            </p>

            <Element
              name="sliding-scales-and-economic-justice"
              className="element"
            >
              <h2>Sliding Scales and Economic Justice</h2>
            </Element>

            <p>
              The poverty rate for disabled populations is double the national
              average. Disability is often the cause and the consequence of
              poverty. This, obviously, restricts the capacity for disabled
              people to spend time in arts and cultural institutions.
              Understandably, arts nonprofits rely in part on ticket sales to
              fund portions of their programming. Yet, thinking through access,
              there are alternative business models that allow for more
              equitable admissions policies. Arts organizations can institute a
              sliding scale admissions policy. Sliding scales acknowledge the
              economic diversity of one’s audience and the impact that lack of
              economic access can have on one’s access to cultural
              organizations. They foreground everyone’s inherent value in a
              given space and create a culture in which everyone feels equally a
              part of the sustainability of an arts organization, within their
              economic means. For example, if the current cost of admission for
              your event is $10, consider creating a pay scale of $6–$15.
            </p>

            <Element name="text" className="element">
              <h2>Text</h2>
            </Element>

            <p>
              There are many guidelines for general print text accessibility,
              but here are a few basic recommendations that can be easily
              adhered to: Organizations should abstain from using all bold or
              all caps for extended blocks of text. When possible, use lists or
              bulleted items. And always use a san-serif typeface for long
              sections of text. To accommodate those with low vision, 16-18
              point font is preferred.
            </p>

            <Element name="touch-tours" className="element">
              <h2>Touch Tours</h2>
            </Element>

            <p>
              If your space puts on exhibitions displaying physical objects,
              consider putting together touch tours of your exhibitions. Touch
              tours increase accessibility for people who are blind or
              vision-impaired by facilitating tactile engagement with art works.
              They can incorporate the work on display or they can use relief
              versions of 2-dimensional work, replicas, and creative props to
              help illustrate exhibitions. Tours can be guided or self-guided
              and can be be even more accessible with the use of Braille maps
              and audio guides for a comprehensive experience.
            </p>

            <Element name="transportation" className="element">
              <h2>Transportation</h2>
            </Element>

            <p>
              The very ability to arrive at an arts institution is deeply
              dependent on the transportation infrastructure that surrounds the
              space. Providing transportation information is a simple and
              critical way to facilitate increased access to your space. In
              event announcements, detailed information about the location of
              your venue should be listed. Some questions to consider when
              writing transportation information: How far is your space from the
              subway or bus station? Is that subway station or bus station
              wheelchair accessible? Are you in a neighborhood where there are
              compromised curb cuts? Are you in a neighborhood that is
              well-serviced by taxis? Is there street parking near your venue?
            </p>

            <p>
              Consider budgeting a small pool of money for the creation of a
              transportation fund for community members for whom transportation
              is an access barrier. Many public transit systems are difficult or
              impossible for disabled people to navigate. Public transportation
              can also be inaccessible for trans people, especially trans
              feminine people of color, who are at risk for assault. Facing a
              physically inaccessible and socially hostile public transit
              system, the ability to travel in a taxi is the difference between
              being able to participate in a cultural space or not. In addition
              to providing transportation information, list your transportation
              fund as an available resource along with event details.
            </p>
          </div>
        </Collapse>
      </div>
    )
  }

  toggle = index => {
    let collapse = "isOpen"

    this.setState(prevState => ({ [collapse]: !prevState[collapse] }))
  }
}

export default Part2
