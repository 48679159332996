import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ScrollToTop from 'react-scroll-up';

import Part1 from "../parts/part-1"
import Part2 from "../parts/part-2"
import Part3 from "../parts/part-3"
import Part4 from "../parts/part-4"

const IndexPage = () => (
  <Layout>
    <SEO title="Accessibility in the Arts: A Promise and a Practice" />
    <Part1 />
    <Part2 />
    <Part3 />
    <Part4 />
    <ScrollToTop
        showUnder={160}
        duration={800}
        style={{
          position: 'fixed',
          fontSize: 24,
          bottom: 12,
          right: 3,
          cursor: 'pointer',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'linear',
          transitionDelay: '0s'
        }}
      >
        <button
          style={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            width: `40px`,
            height: `40px`,
            backgroundColor: `white`,
            color: `black`,
            borderRadius: `50%`,
            fontSize: `1em`,
            fontWeight: `bold`
          }}
          aria-label="Scroll to top of page."
        >&#x2191;</button>
      </ScrollToTop>
  </Layout>
)

export default IndexPage
