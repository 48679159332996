import React from "react"
import ButtonPdf from "./button-pdf"
import ButtonAudio from "./button-audio"

const Header = () => (
  <header>
    <div className="title">
      <h1>Accessibility in the Arts:<br/>A Promise and a Practice</h1>
    </div>
    <div className="buttons">
      <ButtonPdf />
      <ButtonAudio />
    </div>
    <div className="author">
      <h1>Carolyn Lazard</h1>
    </div>
  </header>
)

export default Header
