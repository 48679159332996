import React from 'react'
import { useSpring, animated } from 'react-spring'
import mp3 from "../audio/Accessibility_in_the_Arts_A_Promise_and_a_Practice_Carolyn_Lazard.mp3"

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 1.35) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

function ButtonAudio() {
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))
  return (
    <animated.a
      className="button button-audio"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
      aria-label="Listen audio version."
      href={mp3}
      target="_blank"
    />
  )
}

export default ButtonAudio
