import React from "react"
import cx from "classnames"
import Collapse from "@kunukn/react-collapse"

class Part3 extends React.Component {
  state = {
    isOpen: false,
  }

  render() {
    return (
      <div className="accordion">
        <button
          className={cx("accordion__open-button", {
            "accordion__open-button--active": this.state.isOpen,
          })}
          onClick={() => this.toggle()}
          aria-expanded={this.state.isOpen ? "true" : "false"}
          aria-controls="section3"
          id="accordion3"
        >
          <span className="accordion__trigger">
            Part III
            <br />
            How to List Access Information
          </span>
        </button>

        <Collapse
          isOpen={this.state.isOpen}
          id="section3"
          aria-labelledby="accordion3"
          role="region"
          aria-hidden={this.state.isOpen ? "true" : "false"}
          className={
            "accordion__collapse " +
            (this.state.isOpen ? "accordion__collapse--active" : "")
          }
        >
          <div className="accordion__content">
            <p>
              As organizations make their spaces more accessible, it is also
              important to make information about accessibility available to
              their audiences. Listing access information is a large part of
              facilitating access. This section provides pointers on how to list
              access information in an event description.
            </p>

            <h2>Transparency in Access Notes</h2>

            <p>
              Sometimes organizations will list access information on the
              “About” or “Info” section of their websites, but it is rarely
              featured prominently in relation to events and exhibition cycles.
              Access information should be provided frequently and regularly
              updated. Access information should be synced and consistent across
              all platforms: an organization’s website, facebook event, email
              announcement, and instagram post, should all list the same
              information. Often, institutions do not want to list access
              information because it highlights how inaccessible their spaces
              actually are. The only thing more inaccessible than an
              inaccessible space is not providing information about how the
              space is structured. Listing access information requires that
              institutions address what they can accommodate and especially,
              what they can’t accommodate.
            </p>

            <p>
              When listing access information, organizations should refrain from
              making decisions about how accessible their space is. Instead of
              stating: “there are 3 small steps into the exhibition space,” they
              can state “there are 3, 2 inch steps into the exhibition space.”
              Qualifiers like “small” or a “few” are imprecise. 3 steps, no
              matter how “small,” are inaccessible to wheelchair users. When
              possible, organizations should supply exact measurements about the
              dimensions of entrances, pathways, etc. If staff are unsure of
              about a point of access, it’s best to err on the side of providing
              too many details rather than too few details. A large part of
              facilitating access is about welcoming and maintaining open
              communication with an institution’s public. Programs should not be
              unilaterally developed and then presented to an audience. They
              should be developed with an audience’s needs in mind. At the end
              of a listed access note, staff should make themselves available to
              the public by phone or by email for additional access requests.
              During events, there should be a specific point person who is in
              charge of facilitating access and answering questions about the
              space and the access resources available.
            </p>
          </div>
        </Collapse>
      </div>
    )
  }

  toggle = index => {
    let collapse = "isOpen"

    this.setState(prevState => ({ [collapse]: !prevState[collapse] }))
  }
}

export default Part3
