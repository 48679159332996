import React from "react"
import cx from "classnames"
import Collapse from "@kunukn/react-collapse"

class Part1 extends React.Component {
  state = {
    isOpen: false,
  }

  render() {
    return (
      <div className="accordion">
        <button
          className={cx("accordion__open-button", {
            "accordion__open-button--active": this.state.isOpen,
          })}
          onClick={() => this.toggle()}
          aria-expanded={this.state.isOpen ? "true" : "false"}
          aria-controls="section1"
          id="accordion1"
        >
          <span className="accordion__trigger">
            Part I
            <br />
            Why Accessibility?
          </span>
        </button>

        <Collapse
          isOpen={this.state.isOpen}
          id="section1"
          aria-labelledby="accordion1"
          role="region"
          className={
            "accordion__collapse " +
            (this.state.isOpen ? "accordion__collapse--active" : "")
          }
          aria-hidden={this.state.isOpen ? "true" : "false"}
        >
          <div className="accordion__content">
            <p>
              <em>Accessibility in the Arts: A Promise and a Practice</em> is an
              accessibility guide geared toward small-scale arts nonprofits and
              the potentially expansive publics these organizations serve. It
              details specific ways in which disabled people are excluded from
              cultural spaces and offers possible solutions to those barriers.
              Moving away from historical and juridical definitions of
              accessibility, this guide considers the unique capacity of small
              scale arts organizations to meet the needs of disabled
              communities. It engages principles of disability justice to think
              through what can urgently be done to create more equitable and
              accessible arts spaces.
            </p>

            <p>
              Developed by queer and trans activists of color in the Bay area,
              Disability Justice (DJ) is the second wave of the disability
              rights movement, transforming it from a single issue approach to
              an intersectional, multisystemic way of looking at the world.
              Within this framework, disability is defined as an economic,
              cultural, and/or social exclusion based on a physical,
              psychological, sensory, or cognitive difference. Disability
              Justice movements understand disability to be unevenly
              distributed, primarily affecting black and indigenous communities,
              queer and trans communities, and low income communities.
              Disability is structurally reinforced by ableism, a system rooted
              in the supremacy of non-disabled people and the disenfranchisement
              of disabled people through the denial of access. Accessibility is
              the primary tool that organizations can engage to dismantle
              ableism and create a more inclusive space; it defines the degree
              to which all people can engage with certain resources and
              participate in cultural, social, political, and economic spheres.
            </p>

            <p>
              Arts institutions without considered accessibility measures are
              facing significantly diminished audience members and visitors.
              According the U.S Census Bureau, one-fifth of the U.S, population
              is disabled. Investing in accessibility is a surefire way for any
              small-scale arts organization to expand its viewership. It can
              include a wide variety of actions and policies from making a
              physical space wheelchair accessible and ensuring ASL
              interpretation for public events, to all-gender restrooms and
              sliding-scale ticketing. Prioritizing accessibility in arts spaces
              begins with asking oneself some basic questions: Who comes to our
              events? Why do those people come to our events? Who doesn’t come
              to our events? Why do those people not come to our events?
            </p>

            <p>
              Conversations about access have traditionally been explored within
              a juridical framework. Our current understanding of accessibility
              is heavily reliant on the terms set by the Americans with
              Disabilities Act (ADA). This landmark piece of legislation, signed
              into effect in 1990, requires employers to support the needs of
              disabled workers and provide accommodations to make the workplace
              accessible. It also mandates certain accessibility measures for
              governmental organizations, nonprofits, and businesses which
              service the public. ADA compliance is not a set of predetermined
              standards; the law exempts businesses and organizations with less
              than 15 employees, meaning that many small-scale arts nonprofits
              are not legally obligated to have accessible spaces. As a result,
              many organizations are motivated by the desire to protect
              themselves legally instead of viewing accessibility as
              indispensable.
            </p>

            <p>
              And yet the very definition of “small-scale” that allows
              organizations to evade ADA compliance can be seen as a strength,
              as small-scale arts organizations are perhaps more capable of
              meeting the needs of their audiences than larger institutions. Big
              museums, for example, might have access to more financial
              resources, but are often plagued by bureaucracy and inaccessible
              leadership. A smaller staff can lead to less bureaucracy and
              closer contact with an institution’s public. The person
              introducing the event at a small-scale arts nonprofit might also
              be the person who set out the seats earlier in the evening. These
              systems of organization allow for more flexibility and change
              within an organization. Programs and exhibitions tend to bend to
              the frameworks presented by large arts institutions, whereas
              smaller arts institutions can be redefined with each project they
              engage.
            </p>

            <p>
              ADA compliance is not the only way to create truly inclusive
              cultural spaces. It is critical to address not just the
              infrastructure but the very exhibitions and programs that make an
              institution accessible. Do your exhibitions, screenings,
              performances, and talks reflect the community that you want to
              bring into your space? Do they address the concerns, needs, and
              discourses of said community? How can institutions think through
              their programming and exhibitions in holistic ways that fold into
              and expand out from various communities? Supporting the cultural
              labor of disabled artists and thinkers must happen in tandem with
              infrastructural changes. Additionally, arts organizations need
              disabled art workers in positions of leadership to create actual
              substantial shifts. There is often a striking discord between an
              institution’s desire to represent marginalized communities and a
              total disinvestment from the actual survival of those communities.
              The ideal arts space is simple: it’s one in which art and culture
              are not sequestered from the lived experience of artists and their
              communities.
            </p>

            <p>
              The creation of accessible spaces cannot be done without
              dismantling the pernicious liberalism that pervades our lives and
              our relationships with each other, not just as artists and art
              workers, but as subjects of the state. To commit to disability
              justice is to redefine the terms of subjecthood. It’s to undo the
              rampant individualism that is a fiction for both disabled and
              nondisabled people: everyone has needs. If followed, this guide
              will not produce an ADA-compliant institution, but it will
              hopefully provide some entry points into building a more inclusive
              foundation for the cultural work that an arts organization does.
              Conversations about disability often rely on the idea of
              accessibility as a set of particular, preset interventions, but
              accessibility requires great flexibility. It demands a malleable
              infrastructure that shifts, in real time, with the needs of the
              community. We cannot account for every need that every person will
              ever have.To this end, this guide is in no way meant to be
              comprehensive, but will hopefully change the institutional
              landscape of the arts. Accessibility is a promise, not a
              guarantee. It’s a speculative practice.
            </p>

            {/* <button onClick={() => this.toggle()} className="accordion__close-button">
              <p>Close</p>
            </button> */}
          </div>
        </Collapse>
      </div>
    )
  }

  toggle = index => {
    let collapse = "isOpen"

    this.setState(prevState => ({ [collapse]: !prevState[collapse] }))
  }
}

export default Part1
