import React from "react"
import logo from "../images/recess-logo.svg"

const Footer = () => (
  <footer>
    <div className="colophon">
      <p>COLOPHON</p>
    </div>
    <div className="credits">
      <p>Commissioned by Recess<br/>
      Written by Carolyn Lazard<br/>
      Edited by Kemi Adeyemi<br/>
      Designed by <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://rosentomov.com"
      >Rosen Tomov</a> & Riley Hooker<br/>
      Development by <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://rosentomov.com"
      >Rosen Tomov</a></p>

      <p>First Edition © 2019</p>
    </div>
    <div className="credits">
      <p>This publication is made possible by</p>
      <img src={logo} alt="" />
      <p><a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.recessart.org/"
      >Recess, New York</a></p>
    </div>
  </footer>
)

export default Footer
