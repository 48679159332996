import React from "react"
import cx from "classnames"
import Collapse from "@kunukn/react-collapse"

class Part4 extends React.Component {
  state = {
    isOpen: false,
  }

  render() {
    return (
      <div className="accordion">
        <button
          className={cx("accordion__open-button", {
            "accordion__open-button--active": this.state.isOpen,
          })}
          onClick={() => this.toggle()}
          aria-expanded={this.state.isOpen ? "true" : "false"}
          aria-controls="section4"
          id="accordion4"
        >
          <span className="accordion__trigger">
            Part IV
            <br />
            Budgeting and Additional Resources
          </span>
        </button>

        <Collapse
          isOpen={this.state.isOpen}
          id="section4"
          aria-labelledby="accordion4"
          role="region"
          aria-hidden={this.state.isOpen ? "true" : "false"}
          className={
            "accordion__collapse " +
            (this.state.isOpen ? "accordion__collapse--active" : "")
          }
        >
          <div className="accordion__content">
            <h2>Budgeting</h2>

            <p>
              Accessibility is something that can be achieved regardless of
              resources. It’s about changing an organization's orientation, and
              privileging the needs of its visitors and audience members.
              Organizational budgets become a critical part of this work and
              call for greater accessibility. Institutions can greater meet the
              needs of their publics by starting their accessibility initiatives
              with their budgets. Accessibility, if it isn’t already, should be
              a line item in organization budgets each year. Maybe an arts space
              can’t afford ASL interpretation at every event, but they can
              afford it for 5 events out of the year. Spaces should also budget
              for anticipated, but unknown costs related to increasing access to
              their space.
            </p>

            <h2>Additional Resources</h2>

            <p>
              This access guide is meant to be an introduction to some critical
              considerations in the production of an accessible and integrated
              arts community. Below are some additional resources with more
              detailed information about how to implement some of the
              suggestions and recommendations.
            </p>

            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.amara.org/en/"
                >
                  Amara: Free caption and subtitle editor
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://adata.org/publication/temporary-events-guide"
                >
                  A Planning Guide for Making Temporary Events Accessible to
                  People with Disabilities
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.perkinselearning.org/technology/blog/how-write-alt-text-and-image-descriptions-visually-impaired"
                >
                  Alt Text and Image descriptions and captions: How-to
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.attitudeiseverything.org.uk/"
                >
                  Attitude is Everything: Improving d/Deaf and disabled people’s
                  access to live music
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://sinsinvalid.org/blog/disability-justice-a-working-draft-by-patty-berne"
                >
                  Disability Justice: A working draft by Patty Berne of SINS
                  INVALID
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://leavingevidence.wordpress.com/2011/02/12/changing-the-framework-disability-justice/"
                >
                  Changing the Framework: Disability Justice by Mia Mingus
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.acb.org/adp/index.html"
                >
                  The Audio description project: resources and trainings
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://antenaantena.org/wp-content/uploads/2012/06/langjust_eng.pdf"
                >
                  “How to Build Language Justice”: creating multilingual spaces
                  from Antena
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://sinsinvalid.org/blog/10-principles-of-disability-justice"
                >
                  SINS INVALID: 10 Principles of Disability Justice{" "}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.access-board.gov/"
                >
                  United States Access Board
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://usdac.us/nativeland/"
                >
                  Indigenous Land Acknowledgements
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ada.gov/pcatoolkit/chap5toolkit.htm"
                >
                  ADA Toolkit: Website Accessibility under Title II of the ADA
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.shapearts.org.uk/news/accessible-curating"
                >
                  Shape Arts: How to put on an accessible exhibition
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.shapearts.org.uk/news/accessible-marketing-guide"
                >
                  Shape Arts: An Accessible Arts Marketing Guide
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://weareunlimited.org.uk/demystifying-access-a-resource-pack-for-the-performing-arts/"
                >
                  Demystifying Access: A resource pack for the performing arts
                </a>
              </li>
            </ul>
          </div>
        </Collapse>
      </div>
    )
  }

  toggle = index => {
    let collapse = "isOpen"

    this.setState(prevState => ({ [collapse]: !prevState[collapse] }))
  }
}

export default Part4
